<template>
  <LineChart 
    :chartData="datacollection"
    :options="options"
    ref="ChartPreview"
    :styles="{position: 'relative', 'margin-top': '10px'}">
  </LineChart>
</template>
<script>
  import LineChart from './components/curves/BarChart.js';
  import 'chartjs-plugin-dragdata';
  import cloneDeep from 'lodash/cloneDeep';

  export default {
    name: 'BarChart',
    props: {
      image: {
        default: () => ''
      },
      items: {
        type: Array,
        default: () => []
      },
      setPreview: {
        default: () => []
      }
    },
    components: {
      LineChart
    },
    data() {
      return {
        datacollection: {
          labels: ['12:00 PM', '12:30 PM', '1:00 PM', '1:30 PM', '2:00 PM', '2:30 PM', '3:00 PM', '3:30 PM', '4:00 PM', '4:30 PM', '5:00 PM', '5:30 PM', '6:00 PM', '6:30 PM', '7:00 PM', '7:30 PM', '8:00 PM', '8:30 PM', '9:00 PM', '9:30 PM', '10:00 PM', '10:30 PM', '11:00 PM', '11:30 PM',
            '12:00 AM', '12:30 AM', '1:00 AM', '1:30 AM', '2:00 AM', '2:30 AM', '3:00 AM', '3:30 AM', '4:00 AM', '4:30 AM', '5:00 AM', '5:30 AM', '6:00 AM', '6:30 AM', '7:00 AM', '7:30 AM', '8:00 AM', '8:30 AM', '9:00 AM', '9:30 AM', '10:00 AM', '10:30 AM', '11:00 AM', '11:30 AM', '12:00 PM']
        },
        options: {
          showLines: true,
          animation: false,
          dragData: true,
          scales: {
            xAxes: [{
              ticks: {
                autoSkip: false,
                minRotation: 50,
                fontSize: 11
              }
            }],
            yAxes: [
              {
                ticks: {
                  min: 2,
                  max: 100,
                  stepSize: 10
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Dim Level'
                }
              }
            ]
          },
          offset: true,
          layout: {
            padding: 5
          },
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 0,
          legend: {
            display: false
          },
          tooltips: {
            animation: true,
            mode: 'point',
            intersect: true
          },
          dragDataRound: 0
        }
      };
    },
    mounted() {
      this.fillData();
    },
    methods: {
      fillData() {
        const datasets = cloneDeep(this.items);
        const datacollection = {
          labels: this.datacollection.labels,
          datasets: []
        };
        for (let i = 1; i < datasets.length; i++) {
          datacollection.datasets = [{
            label: datacollection.labels[i],
            data: datasets,
            backgroundColor: '#ffeb3b',
            borderColor: '#ffeb3b',
            borderWidth: 10, 
            categoryPercentage: 1.0,
            barPercentage: 1.0
          }];
        }
        this.datacollection = datacollection;
        this.$nextTick(() => {
          this.saveDatasets();
        });
      },
      saveDatasets() {
        if (this.$refs) {
          const component = this.$refs['ChartPreview'];
          if (component) {
            const image = component.$refs.canvas.toDataURL('image/png');
            this.setPreview(image);
          }
        }
      }
    },
    watch: {
      items() {
        this.fillData();
      }
    }
  };

</script>

<style>
  .small {
    max-width: 1000px;
    margin: 0 auto;
  }

  #bar-chart:hover {
    cursor: pointer;
  }
</style>
